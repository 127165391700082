import React from 'react';

const PDMLogoSVG = `

<?xml version="1.0" encoding="UTF-8"?>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 45 45" enable-background="new 0 0 45 45" xml:space="preserve">
<path fill="#EF4136" d="M0,2.7v18.4h12.3c-1.8-1.1-3.1-2.6-3.1-4.4c0-4.1,3.4-7.5,7.5-7.5c1.9,0,3.4,1.5,4.5,3.3V0H2.7
	C1.2,0,0,1.2,0,2.7z"/>
<path fill="#1C75BC" d="M42.3,0H23.9v12.4c1.1-1.8,2.5-3.2,4.4-3.2c4.1,0,7.5,3.4,7.5,7.5c0,1.8-1.3,3.2-3.1,4.4H45V2.7
	C45,1.2,43.8,0,42.3,0z"/>
<path fill="#39B54A" d="M10.3,32.3l8.3-8.4H0v18.4C0,43.8,1.2,45,2.7,45h18.4V26.3l-8.3,8.4L10.3,32.3z"/>
<path fill="#FFDE17" d="M45,23.9H26.2l8.3,8.4l-2.4,2.4l-8.2-8.3V45h18.4c1.5,0,2.7-1.2,2.7-2.7"/>
<path fill="#FFDE17" d="M16.6,12.6c-2.3,0-4.2,1.9-4.2,4.2c0,1.1,4.2,3.2,8.1,4C19.9,17,17.8,12.6,16.6,12.6z"/>
<path fill="#39B54A" d="M32.4,16.7c0-2.3-1.9-4.2-4.2-4.2c-1.2,0-3.3,4.4-4,8.1C28.3,20,32.4,17.8,32.4,16.7z"/>
</svg>
`;

const PDMLogo = () => {
  return (
    <div dangerouslySetInnerHTML={{ __html: PDMLogoSVG }} />
  );
};

export default PDMLogo;
